
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { themeName } from "@/core/helpers/documentation";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";
import Notice from "@/view/content/sections/Notice.vue";

export default defineComponent({
  name: "build",
  components: {
    CodeHighlighter,
    Notice,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Build");
    });

    return {
      themeName,
    };
  },
});
